<template>
  <div class="container-fluid p-0">
    <PageTitle :title="$t('label.areaProtegida')" :titleAdd="$t('label.adicionarAreaProtegida')" :isAdd="true"/>
    <!-- filtro -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body p-0 box-dashboard">
            <div class="align-self-center">
              <form class="row g-3 p-3 pb-0 filtro_especie" @submit.prevent="search">
                <div class="col-12 col-lg-4">
                  <input type="text" class="form-control bg-laranja" :placeholder="$t('label.descricao')" v-model="formSearch.protected_area"/>
                </div>
                <div class="col-12 col-lg-2 ml-auto text-right pt-1">
                  <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                    {{ $t('label.limpar') }}
                  </button>
                  <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                    Buscar
                  </button>
                </div>
              </form>

              <div class="row mt-1 mt-xl-2 p-3 bg_table_header">
                <div class="col-auto d-none d-sm-block">
                  <p class="mb-0 mt-2">
                    {{ $t('label.numRegistrosEncontrados') }}
                    <span class="laranja">{{$store.state.countList}}</span>
                  </p>
                </div>

                <div class="col-auto ml-auto text-right select_registro">
                  <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                    <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                    <option value="25">{{ $t('label.exibir25Reg') }}</option>
                    <option value="50">{{ $t('label.exibir50Reg') }}</option>
                    <option value="100">{{ $t('label.exibir100Reg') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /filtro -->

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="table-responsive full_table mt-0">
                <table class="table caption-top">
                  <thead class="bg_table_header">
                    <tr>
                      <th class="col-md-8 py-3 pl-8">{{ $t('label.areaProtegida') }}</th>
                      <th class="col-md-4 py-3 pl-4 pr-4 text-right">{{ $t('label.acoes') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="$store.state.countList === 0"><td colspan="4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                    <tr v-else v-for="item in items" :key="item.name">
                      <td class="py-3 pl-4">{{item.protected_area}}</td>
                      <td class="py-3">
                        <ul class="acoes text-right pr-1">
                          <li>
                            <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                          </li>
                          <li>
                            <a @click="action($actionDelete, item)" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'"><span class="apagar"></span></a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <TablePagination v-on:searchTable="search()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
import PageTitle from '@/components/shared/PageTitle'
export default {
  name: 'AreaProtegida',
  components: { TablePagination, PageTitle },
  data: function () {
    return {
      formSearch: {},
      items: [],
      listPagination: [],
      rangePagination: [],
      countPages: 0
    }
  },
  methods: {
    clear: function () {
      this.formSearch = {}
    },
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        this.$router.push({ name: 'AreaProtegidaForm', params: { id: item.protected_area_id } })
      }
    },
    delete: function (item) {
      axios.delete(`/VocProtectedArea/${item.protected_area_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$store.dispatch('showPreload')

      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/VocProtectedArea/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    }
  },
  created: function () {
    this.search()
  }
}
</script>
